import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteChildrenProps } from 'react-router';

import PLUItemStore from 'stores/Settings/PLUItemStore';
import { PLUItemSteps } from 'stores/Settings/types';
import RootStore from 'stores/RootStore';
import { ROUTES } from 'routes/routes';

import FormStepper, {
  FormStepperBreadcrumb,
  FormStepperStep,
} from 'components/FormStepper/FormStepper';
import PLUItemStepSoftware from './Steps/PLUItemStepSoftware';
import PLUItemStepDate from './Steps/PLUItemStepDate';
import PLUItemStepAssignment from './Steps/PLUItemStepAssignment';
import PLUItemStepHours from './Steps/PLUItemStepHours';

type Props = RouteChildrenProps<{
  id: string;
}>;

@observer
class PLUItem extends Component<Props> {
  @observable private _store: PLUItemStore;

  private _pluItemBreadcrumbs: FormStepperBreadcrumb[] = [
    { to: ROUTES.admin.settings, name: 'title.settings' },
    { name: RootStore.localization.formatMessage('title.settings.plu') },
  ];

  constructor(props: Props) {
    super(props);

    const id = (props.match && props.match.params.id) || '';

    this._store = new PLUItemStore(id);
  }

  async componentDidMount() {
    await this._store.init();
  }

  render() {
    return (
      <FormStepper
        activeStep={this._store.activeStep}
        steps={this._steps}
        breadcrumbs={this._pluItemBreadcrumbs}
        loading={this._store.loading}
        isNew={!this._store.id}
        onStepBackClick={this._store.handleStepBack}
        onStepNextClick={this._store.handleStepNext}
        onStepChange={this._store.handleStepChange}
        onSubmit={this._store.handleSubmit}
        onDelete={this._store.handleDelete}
      >
        {this._renderActiveStepForm()}
      </FormStepper>
    );
  }

  private _renderActiveStepForm = () => {
    switch (this._store.activeStep) {
      case PLUItemSteps.Software:
        return <PLUItemStepSoftware form={this._store.form} />;
      case PLUItemSteps.Date:
        return <PLUItemStepDate form={this._store.form} />;
      case PLUItemSteps.Assignment:
        return (
          <PLUItemStepAssignment
            form={this._store.form}
            menuItems={this._store.menuItems}
          />
        );
      case PLUItemSteps.Hours:
        return <PLUItemStepHours form={this._store.form} />;
      default:
        return;
    }
  };

  private _hasStepErrors = (formFields: string[]) => {
    const { form } = this._store;
    if (!form.validated) return false;

    for (const field of formFields) {
      if (form.errorFor(field).length) {
        return true;
      }
    }

    return false;
  };

  private get _steps(): FormStepperStep[] {
    return [
      {
        label: 'title.settings.plu.steps.software',
        step: PLUItemSteps.Software,
        hasError: this._hasStepErrors(['component']),
      },
      {
        label: 'title.settings.plu.steps.date',
        step: PLUItemSteps.Date,
        hasError: this._hasStepErrors(['period, weekDays']),
      },
      {
        label: 'title.settings.plu.steps.assignment',
        step: PLUItemSteps.Assignment,
        hasError: this._hasStepErrors(['alias', 'menuItemId', 'cell']),
      },
      {
        label: 'title.settings.plu.steps.hours',
        step: PLUItemSteps.Hours,
        hasError: this._hasStepErrors(['availableTime', 'menuItemType']),
      },
    ];
  }
}

export default PLUItem;
