import { DateTime } from 'luxon';
import { action, computed, observable, reaction } from 'mobx';

import { PLUItem, PLUItemComponent } from 'http/Api/PLUItems/types';
import { getLocalDateTime } from 'helpers/datetime';
import httpFacade from 'http/httpFacade';
import { MenuItem } from 'stores/Menu/types';

// week
const PERIOD = 7;
export enum PLUPlannerTab {
  Spt,
  Consumer,
}

class PLUPlannerStore {
  @observable public date = getLocalDateTime();
  @observable public activeWeekDate = '';
  @observable public tab = PLUPlannerTab.Spt;
  @observable public loading = false;
  @observable public menuItems: MenuItem[] = [];

  @observable private _pluItems: { [cell: string]: PLUItem[] } = {};

  constructor() {
    reaction(
      () => this.date,
      async () => {
        await this.fetchCurrentPeriod();
      },
    );
  }

  @action
  public init = async () => {
    const [menuItems] = await Promise.all([
      httpFacade.menuItems.fetchMenuItems(),
      this.fetchCurrentPeriod(),
    ]);

    this.menuItems = menuItems.data;
  };

  @action
  public fetchCurrentPeriod = async () => {
    try {
      this.loading = true;

      const [items] = await Promise.all([
        httpFacade.pluItems.fetchPLUItemsByPeriod(
          this.weekStartDate.toISODate(),
          this.weekEndDate.toISODate(),
        ),
      ]);

      this._pluItems = items?.data;
    } finally {
      this.loading = false;
    }
  };

  @action
  public toPrevWeek = () => {
    this.date = this.date.minus({ week: 1 });
  };

  @action
  public toNextWeek = () => {
    this.date = this.date.plus({ week: 1 });
  };

  @action
  public changeDate = ([date]: DateTime[]) => {
    this.date = date;
  };

  @action
  public handleTabChange = (tab: PLUPlannerTab) => {
    this.tab = tab;
  };

  public getItemTitle = (pluItem: PLUItem) => {
    const { alias, pluId, menuItemId } = pluItem;

    if (alias) return alias;

    const menuItem = this.menuItems.find(item => item.id === menuItemId);
    if (menuItem) return menuItem.title;

    return pluId;
  };

  @action
  public handleDelete = async (id: string) => {
    await httpFacade.pluItems.deletePLUItem(id);

    const items = JSON.parse(JSON.stringify(this._pluItems)) as {
      [cell: string]: PLUItem[];
    };

    for (const cell in items) {
      if (!items.hasOwnProperty(cell)) continue;

      items[cell] = items[cell].filter(item => item.pluId !== id);
    }

    this._pluItems = items;
  };

  @computed
  public get pluItems() {
    const items = JSON.parse(JSON.stringify(this._pluItems)) as {
      [cell: string]: PLUItem[];
    };

    for (const cell in items) {
      if (!items.hasOwnProperty(cell)) continue;

      items[cell] = items[cell].filter(item => {
        if (item.component === PLUItemComponent.All) return true;

        switch (this.tab) {
          case PLUPlannerTab.Spt: {
            return item.component === PLUItemComponent.Spt;
          }
          case PLUPlannerTab.Consumer: {
            return item.component === PLUItemComponent.Consumer;
          }
          default:
            return false;
        }
      });
    }

    return items;
  }

  @computed
  public get weekStartDate() {
    return this.date.startOf('week');
  }

  @computed
  public get weekEndDate() {
    return this.date.endOf('week');
  }

  @computed
  public get weekNumber() {
    return this.date.weekNumber;
  }

  @computed
  public get periodDates(): string[] {
    const dates: string[] = [];

    for (
      let day = this.weekStartDate;
      day.weekday <= PERIOD && dates.length < PERIOD;
      day = day.plus({ day: 1 })
    ) {
      dates.push(day.toISODate());
    }

    return dates;
  }

  @computed
  public get availableWeekDays(): string[] {
    return this.periodDates.filter(period => period >= this.date.toISODate());
  }
}

export default PLUPlannerStore;
